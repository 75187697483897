
import { Modal, Button } from "antd";
import React, {useRef} from "react";
import { useReactToPrint } from 'react-to-print';
import { NumericFormat } from 'react-number-format';
import moment from "moment";
import './FacturaPrint.css'
export const FacturaPrint = (props) => {
    const componentRef = useRef();
    const factura = props.value;
    const impuesto = props.value.impuesto
    let total = 0
    let valorImpuesto = 0
    for(let item of factura.FacturaItems){
      total += item.cantidad * item.precio_venta
    }
    if(impuesto>0){
      valorImpuesto = total * (impuesto/100)
      total += valorImpuesto
     }

    const handleCancel = () =>{
      props.updateVisible(false)
    }

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
      });

        return (
            <Modal visible={props.visible} onCancel={handleCancel} onOk={handleCancel}>
            

            <div className="tira" ref={componentRef} >
              <div className="tiendaNombre">{factura.Bodega.Empresa.nombre}</div>
              <div>Sucursal: {factura.Bodega.nombre}</div>
              <div>NIT: {factura.Bodega.Empresa.nit} </div>
              <div>{factura.Bodega.Empresa.direccion} </div>
              <div>Tel. {factura.Bodega.Empresa.telefono} </div>
              <div>REGIMEN SIMPLIFICADO</div>
              <br />
              <div>Factura de Venta</div>
              <div>Ref: FV-{factura.num_factura}</div>
              <br />
              <div>SEÑOR(ES): Clientes Varios</div>
        <div>VENDEDOR(A): </div>
        <div>FECHA DE EXPEDICIÓN (DD/MM/AA)</div>
        <div>{moment(factura.createdAt).format('DD/MM/YYYY')}</div>

          <br />
        <div>PRODUCTOS</div>

        {factura.FacturaItems.map((item=>{
          return <div>{item.Producto.nombre} x {item.cantidad} = $ <NumericFormat value={item.cantidad * item.precio_venta} displayType="text" allowLeadingZeros thousandSeparator="," />   </div>
        }))}

<br /> <div >Impuesto: $ <NumericFormat value={valorImpuesto} displayType="text" allowLeadingZeros thousandSeparator="," /></div>
        <div className="totalFactura">Total Factura: $ <NumericFormat value={total} displayType="text" allowLeadingZeros thousandSeparator="," /></div>
            <br />
            <p className="pie">Esta factura se asimila en todos sus efectos a una letra
        de cambio de conformidad con el Art. 774 del código de
        comercio. Autorizo que en caso de incumplimiento de
        esta obligación sea reportado a las centrales de riesgo,
        se cobraran intereses por mora.</p>
        <p className="pie">Generado en appvinta.com</p>
        <p class="pie"> Tus ventas mejor con Vinta ®</p>
             </div>
              <div className="botonera">
             <Button type="primary" onClick={handlePrint}>Imprimir Factura</Button>
             </div>
            </Modal>
        )
  
}