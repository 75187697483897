import React, { useState, useEffect } from "react";
import Layout from "../../shared/Layout/Layout";
import { Table, Button, Modal, Form, Input, Tooltip } from "antd";
import apiService from "../../services/api";
import { useNavigate } from "react-router-dom"
import { useAuth } from '../../context/auth.context';
import {EditOutlined, OrderedListOutlined, UnorderedListOutlined,ShoppingCartOutlined,DollarCircleOutlined } from '@ant-design/icons';
const Bodega = () => {
  const [viewModal, setViewModal] = useState(false);
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const navigate = useNavigate()
  const {user,bodegas} = useAuth()
  const empresa_id = user.empresa_id
  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    setData(bodegas)
    // apiService.getBy("bodegas", empresa_id)
    //   .then((response) => {
    //     console.log(response.data);
    //     if(response.data.length>0){
    //     setData(response.data);
    //   }
    //   })
    //   .catch(function(error) {
    //     console.log(error);
    //   });
  };

  const saveData = (values) => {
    console.log(values);
    let form = values
    form.empresa_id = empresa_id
      apiService.post("bodegas", form)
        .then((response) => {
          console.log(response);
          setViewModal(false);
          //form.resetFields();
          getData()
        })
        .catch(function(error) {
          console.log(error);
        });
    

    // const pushData = [...datos, values];
    // setDatos(pushData);
  
  };

  const callModal = () => {
    setViewModal(true);
  };

  const sendCancel = () => {
    setViewModal(false);
  };

  const toInventario = (record) =>{
    navigate("/inventario", { state: { id: record.id, nombre:record.nombre } });
  }

  const toInventarioInsumos = (record) =>{
    navigate("/inventario/insumos", { state: { id: record.id, nombre:record.nombre } });
  }

  const toPOS = (record) =>{
    navigate("/pos", { state: { id: record.id, nombre:record.nombre,impuesto:record.impuesto } });
  }

  const toFacturacion = (record) =>{
    navigate("/factura", { state: { id: record.id, nombre:record.nombre } });
  }


  const columns = [
    {
       title: "Nombre",
      // dataIndex: "nombre",
      // key: "nombre",
      render: (record) => <div>{record.Bodega.nombre}</div>,
    },

    {
      title: "Opciones",

      render: (record) => <div>
        {/* <Button><EditOutlined /></Button>   */}
      <Tooltip title="Inventario de Productos">
     <Button onClick={()=>toInventario(record.Bodega)}><UnorderedListOutlined /></Button>
    </Tooltip> 
    
    <Tooltip title="Inventario de Insumos">
      <Button onClick={()=>toInventarioInsumos(record.Bodega)}><OrderedListOutlined /></Button>
       </Tooltip>

       <Tooltip title="Punto de Venta">
      <Button onClick={()=>toPOS(record.Bodega)}><ShoppingCartOutlined /></Button>
       </Tooltip>

       <Tooltip title="Facturas">
      <Button onClick={()=>toFacturacion(record.Bodega)}><DollarCircleOutlined /></Button>
       </Tooltip>
      
      </div> ,
    
    },
  ];

  return (
    <Layout>
      {/* <Button type="primary" className="new_button" onClick={callModal}>
        Crear Bodega
      </Button> */}

      <Table columns={columns} dataSource={data} />

      <Modal
        title="Crear Bodega"
        visible={viewModal}
        onCancel={sendCancel}
        onOk={form.submit}
      >
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 20 }}
          autoComplete="off"
          layout="vertical"
          form={form}
          onFinish={saveData}
        >
          <Form.Item
            label="Nombre"
            name="nombre"
            rules={[{ required: true, message: "Ingrese el nombre" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Impuesto"
            name="impuesto"
            rules={[{ required: true, message: "Ingrese el valor del impuesto" }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </Layout>
  );
};

export default Bodega;
