
import './App.css';
import Home from './pages/Home/Home';
import { BrowserRouter,Routes,Route } from 'react-router-dom'
import React from 'react'
import Categoria from './pages/Categoria/Categoria';
import Producto from './pages/Producto/Producto';
import Insumo from './pages/Insumo/Insumo';
import Pos from './pages/Pos/Pos';
import Bodega from './pages/Bodega/Bodega';
import Inventario from './pages/Inventario/Inventario';
import ProductoInsumo from './pages/ProductoInsumo/ProductoInsumo';
import InventarioInsumo from './pages/InventarioInsumo/InventarioInsumo';
import Login from './pages/Login/Login';
import { AuthProvider } from './context/auth.context';
import { ProtectedRoute } from './routes/ProtectedRoute';
import Factura from './pages/Factura/Factura';
import Reporte from './pages/Reporte/Reporte';
function App() {
  return (
    <BrowserRouter>
    <AuthProvider>
    <Routes>
          <Route  path="/login" element={<Login />} />
          <Route  path="/" element={ <ProtectedRoute> <Home /> </ProtectedRoute> } />
          <Route  path="/categoria" element={ <ProtectedRoute> <Categoria /> </ProtectedRoute>} />
          <Route  path="/producto" element={ <ProtectedRoute> <Producto /> </ProtectedRoute>} />
          <Route  path="/insumo" element={ <ProtectedRoute> <Insumo /> </ProtectedRoute>} />
          <Route  path="/pos" element={ <ProtectedRoute> <Pos /> </ProtectedRoute>} />
          <Route  path="/bodega" element={ <ProtectedRoute> <Bodega /> </ProtectedRoute>} />
          <Route  path="/inventario" element={ <ProtectedRoute> <Inventario /> </ProtectedRoute>} />
          <Route  path="/producto/insumo" element={ <ProtectedRoute> <ProductoInsumo /> </ProtectedRoute>} />
          <Route  path="/inventario/insumos" element={<ProtectedRoute> <InventarioInsumo /> </ProtectedRoute>} />
          <Route  path="/factura" element={ <ProtectedRoute> <Factura /> </ProtectedRoute>} />
          <Route  path="/reporte" element={ <ProtectedRoute> <Reporte /> </ProtectedRoute>} />
      </Routes>
      </AuthProvider>
      </BrowserRouter>
  );
}

export default App;
