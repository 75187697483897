import React, {useEffect, useState} from 'react';
import Layout from '../../shared/Layout/Layout';
import { useLocation } from "react-router-dom";
import { NumericFormat } from 'react-number-format';
import { Row, Col, Input, Table, Button, Divider } from "antd";
import apiService from "../../services/api";
import AjusteModal from '../../components/modals/AjusteModal';
import AddInsumoModal from '../../components/modals/AddInsumoModal';
const InventarioInsumo = () => {
    const location = useLocation();
    const {id,nombre} = location.state
    const [data,setData] = useState([])
    const [itemSelected,setItemSelected] = useState()
    const [viewNewAjuste, setViewNewAjuste] = useState(false);
    const [loading, setLoading] = useState(false);
    const [viewNewInsumoModal, setViewNewInsumoModal] = useState(false);
    useEffect(() => {
        getInventario();
      }, []);


      const getInventario = () => {
        apiService
          .getBy("inventario/insumos", id)
          .then((response) => {
            console.log(response.data);
            if (response.data.length > 0) {
             setData(formatInventario(response.data));
            }
          })
          .catch(function(error) {
            console.log(error);
          });
      };

      const formatInventario = (data) => {
        let myArrayData = [];
        for (let item of data) {
          let myObjectsData = {};
          myObjectsData["id"] = item.insumo_id;
          myObjectsData["cantidad_inventario"] = item.cantidad;
          myObjectsData["nombre"] = item.Insumo.nombre;
          myObjectsData["unidad"] = item.Insumo.unidad;
    
          myArrayData.push(myObjectsData);
        }
        return myArrayData;
      };

      const viewAjuste = (record) =>{
     
        setViewNewAjuste(true)
        setItemSelected(record)
        console.log(record)
      
      }

      const columns = [
        {
          title: "Nombre",
          dataIndex: "nombre",
          key: "nombre",
        },
    
        {
          title: "Cantidad",
          render: (record) => <div>{record.cantidad_inventario} ({record.unidad}) </div>
        },
       

    
        {
          title: "Opciones",
    
          render: (record) => (
            <Button onClick={()=>{viewAjuste(record)}} >Ajustar</Button>
          ),
        },
      ];

      const handleAddInsumoModal = ()=>{
        setViewNewInsumoModal(true)
      }

    return (
        <Layout>
            <Divider orientation="left" plain>
Inventario de Insumos <strong>{nombre}</strong> 
    </Divider>
            
    <Button type='primary' className="new_button" onClick={handleAddInsumoModal}>Agregar Insumo</Button>

            <Table dataSource={data} columns={columns}></Table>


            { itemSelected && (
            <AjusteModal
        visible={viewNewAjuste}
        bodega_id = {id}
        tipo = "Insumo"
        updateVisible={setViewNewAjuste}
        updateLoading={setLoading}
        updateListInventario={getInventario}
      >
        {itemSelected}
      </AjusteModal>
      )}


{viewNewInsumoModal && (
        <AddInsumoModal visible={viewNewInsumoModal} bodega_id={id}  updateLoading={setLoading} updateVisible={setViewNewInsumoModal} updateListInventario={getInventario}>

        </AddInsumoModal>
      )}

        </Layout>
    );
};

export default InventarioInsumo;