import http from "../utils/http-common";

class ApiService {
   get(url) {
     return http.get(`/${url}`);
   }
   getBy(url,id) {
     return http.get(`/${url}/forain/${id}`);
   }

   inactive(url,id) {
    return http.get(`/${url}/${id}`);
  }

   post(url,data) {
     return http.post(`/${url}`, data);
   }
   update(url,id, data) {
     return http.put(`/${url}/${id}`, data);
   }
   delete(url,id) {
     return http.delete(`/${url}/${id}`);
   }
  
 }
 export default new ApiService();