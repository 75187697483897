import React from 'react';
import Layout from '../../shared/Layout/Layout';

const Home = () => {
    return (
        <Layout>
           
        </Layout>
    );
};

export default Home;