import React, { useState, useEffect } from "react";
import Layout from "../../shared/Layout/Layout";
import { Table, Button, Modal, Form, Input } from "antd";
import apiService from "../../services/api";
import {PrinterOutlined, CloseCircleOutlined,ExclamationCircleOutlined} from '@ant-design/icons';
import { useAuth } from '../../context/auth.context';
import { useLocation } from "react-router-dom";
import { NumericFormat } from 'react-number-format';
import { FacturaPrint } from "../../shared/FacturaPrint/FacturaPrint";
import moment from 'moment'

const { confirm } = Modal;

const Factura = () => {
  const [viewModal, setViewModal] = useState(false);
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [factura,setFactura] = useState()
  const {user} = useAuth()
  const location = useLocation();
  const {id,nombre} = location.state

  const bodega_id = id
  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    apiService.getBy("facturas", bodega_id)
      .then((response) => {
        console.log(response.data);
        if(response.data.length>0){
        setData(response.data);
         }
      })
      .catch(function(error) {
        console.log(error);
      });
  };




  const callModal = (record) => {
    setViewModal(true);
    setFactura(record)
  };

  const anular = (record) =>{
    confirm({
      title: 'Desea anular esta factura?',
      icon: <ExclamationCircleOutlined />,
      content: 'No se podra recuperar',
      onOk() {
        apiService.inactive('facturas/anular',record.num_factura).then((response) => {
          console.log(response);
          //form.resetFields();
          getData()
        })
        .catch(function(error) {
          console.log(error);
        });
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }



  const totalFactura = (items,impuesto) =>{
    let total = 0
    let valorImpuesto = 0
      for(let item of items){
        total += item.precio_venta * item.cantidad
      }
      if(impuesto>0){
       valorImpuesto = total * (impuesto/100)
      }
    return total + valorImpuesto;
  }

  const columns = [
    {
      title: "No. Factura",
      dataIndex: "num_factura",
      key: "num_factura",
      //render: (text) => <a>{text}</a>,
    },

    {
      title: "Impuesto",
      dataIndex: "impuesto",
      key: "impuesto",
      //render: (text) => <a>{text}</a>,
    },

    {
      title: "Total",
      //dataIndex: "num_factura",
      //key: "num_factura",
      render: (record) => <NumericFormat value={totalFactura(record.FacturaItems,record.impuesto)} displayType="text" allowLeadingZeros thousandSeparator="," />,
    },


    {
      title: "Estado",
      dataIndex: "estado",
      key: "estado",
      //render: (text) => <a>{text}</a>,
    },

    {
      title: "Fecha",
      //dataIndex: "num_factura",
      //key: "num_factura",
      render: (record) => <div>{moment(record.createdAt).format("DD-MM-YYYY hh:mm a")}</div>,
    },



    {
      title: "Opciones",

      render: (record) => { if(record.estado == "Generada"){ return <>
      <Button onClick={()=>{callModal(record)}}><PrinterOutlined /></Button>
      <Button type="danger" onClick={()=>{anular(record)}}><CloseCircleOutlined /></Button>
      </>
      }},
    },
  ];

  return (
    <Layout>
     

      <Table columns={columns} dataSource={data} />

      {viewModal && factura && (
  <FacturaPrint visible={viewModal}  value={factura} updateVisible= {setViewModal} />
  )}

    
    </Layout>
  );
};

export default Factura;
