import { Button, Checkbox, Form, Input } from 'antd';
import React from 'react';
import apiService from "../../services/api";
import { useAuth } from '../../context/auth.context';
import { useNavigate } from 'react-router-dom';
import {openNotification} from "../../services/notification"
const Login = () => {

 

    const navigate = useNavigate()
    const {saveUser,saveRole,saveBodegas} = useAuth()

    const onFinish = (values) => {
        apiService.post("usuarios/login", values)
        .then((response) => {
          
          if(response.data[0]){
            console.log(response.data[0]);
           
           saveUser(response.data[0])
           saveRole(response.data[0].role)
           saveBodegas(response.data[0].BodegaUsuarios)

            navigate("/")
            openNotification('success',"Bienvenido "+response.data[0].nombre, "")
          }
        })
        .catch(function(error) {
          console.log(error);
          openNotification('error',"Ups!", "El usuario o password no son válidos")
        });
      };
    
      const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
      };

    return (
      <>
      <div className='login'>
        <div className='form'>
        <img className='logo_inicial' src="./assets/img/logo.png" alt="" />
        <Form className='form-container'
      name="basic"
      layout='vertical'
      labelCol={{
        span: 24,
      }}
      wrapperCol={{
        span: 24,
      }}
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <Form.Item
        label="Usuario"
        name="username"
        rules={[
          {
            required: true,
            message: 'Por favor ingrese su Usuario!',
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Password"
        name="password"
        rules={[
          {
            required: true,
            message: 'Por favor ingrese su Password!',
          },
        ]}
      >
        <Input.Password />
      </Form.Item>

   

      <Form.Item
        wrapperCol={{
          offset: 8,
          span: 16,
        }}
      >
        <Button type="primary" htmlType="submit">
          Iniciar
        </Button>
      </Form.Item>
    </Form>
    </div>
    </div>
    </>
    );
   
};

export default Login;