import React from 'react';
import Navbar from '../../components/Navbar/Navbar';
import './Layout.css'
const Layout = (props) => {
    return (
        <div className='layout'>
            <Navbar></Navbar>
            <div className='container'> 
            {props.children}
            </div>
        </div>
    );
};

export default Layout;