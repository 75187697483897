import React, {useEffect, useState} from 'react';
import Layout from '../../shared/Layout/Layout';
import { useLocation } from "react-router-dom";
import { NumericFormat } from 'react-number-format';
import { Row, Col, Input, Table, Button, Divider } from "antd";
import apiService from "../../services/api";
import AjusteModal from '../../components/modals/AjusteModal';
import AddProductoModal from '../../components/modals/AddProductoModal';

const Inventario = () => {
    const location = useLocation();
    const {id,nombre} = location.state
    const [data,setData] = useState([])
    const [itemSelected,setItemSelected] = useState()
    const [viewNewAjuste, setViewNewAjuste] = useState(false);
    const [viewNewProductoModal, setViewNewProductoModal] = useState(false);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        getInventario();
      }, []);


      const getInventario = () => {
        apiService
          .getBy("inventario", id)
          .then((response) => {
            console.log(response.data);
            if (response.data.length > 0) {
             setData(formatInventario(response.data));
            }
          })
          .catch(function(error) {
            console.log(error);
          });
      };

      const formatInventario = (data) => {
        let myArrayData = [];
        for (let item of data) {
          if(item.Producto.estado == "Activo"){
          let myObjectsData = {};
          myObjectsData["id"] = item.producto_id;
          myObjectsData["cantidad_inventario"] = item.cantidad;
          myObjectsData["nombre"] = item.Producto.nombre;
          myObjectsData["tipo"] = item.Producto.tipo;
          myObjectsData["precio_venta"] = item.Producto.valor_venta;
    
          myArrayData.push(myObjectsData);
        }
        }
        return myArrayData;
      };

      const viewAjuste = (record) =>{
        if(record.tipo == "Único"){
        setViewNewAjuste(true)
        setItemSelected(record)
      }
      }

      const columns = [
        {
          title: "Nombre",
          dataIndex: "nombre",
          key: "nombre",
        },
    
        {
          title: "Cantidad",
          dataIndex: "cantidad_inventario",
          key: "cantidad_inventario",
        },
        {
          title: "Precio Venta",
          //dataIndex: "precio_venta",
          //key: "precio_venta",
           render: (record) => <NumericFormat value={record.precio_venta} displayType="text" allowLeadingZeros thousandSeparator="," />,
        },

        {
            title: "Tipo",
            dataIndex: "tipo",
            key: "tipo",
          },
    
        {
          title: "Opciones",
    
          render: (record) => {if(record.tipo == "Único") {return (<Button onClick={()=>{viewAjuste(record)}} >Ajustar</Button>
          )}}
        },
      ];


      const handleAddProductoModal = ()=>{
        setViewNewProductoModal(true)
      }

    return (
        <Layout>

<Divider orientation="left" plain>
Inventario de Productos <strong>{nombre}</strong> 
    </Divider>

    <Button type='primary' className="new_button" onClick={handleAddProductoModal}>Agregar Producto</Button>
            
            
            <Table dataSource={data} columns={columns}></Table>

{ itemSelected && (
            <AjusteModal
        visible={viewNewAjuste}
        bodega_id = {id}
        tipo = "Producto"
        updateVisible={setViewNewAjuste}
        updateLoading={setLoading}
        updateListInventario={getInventario}
      >
        {itemSelected}
      </AjusteModal>
      )}


      {viewNewProductoModal && (
        <AddProductoModal visible={viewNewProductoModal} bodega_id={id}  updateLoading={setLoading} updateVisible={setViewNewProductoModal} updateListInventario={getInventario}>

        </AddProductoModal>
      )}

        </Layout>
    );
};

export default Inventario;