import React from 'react';
import './Navbar.css'
import {Link} from 'react-router-dom'
import { useAuth } from '../../context/auth.context';
import {
  UserOutlined
} from '@ant-design/icons';
const Navbar = () => {
  const {user,setUser,setRole,setBodegas} = useAuth()

  const logout = () =>{
    setUser(null)
    setRole(null)
    setBodegas(null)
  }

    return (
        <div className='navbar'>
          {/* <div >
            <img className='logo' src="../assets/img/logo.png" alt="" />
          </div> */}
          <ul className='menu'>
             <Link to='/categoria'><li>Categorias</li></Link> 
            <Link to='/producto'> <li>Productos</li></Link> 
            <Link to='/insumo'> <li>Insumos</li></Link> 
            <Link to='/bodega'> <li>Bodegas</li></Link> 
            <Link to='/reporte'> <li>Reportes</li></Link> 
           
          </ul>


          <div>
          <div className='username'> <UserOutlined /> {user.nombre} <Link to='/' onClick={logout} className='salir'> Salir</Link> </div>
          
          </div>

        </div>
    );
};

export default Navbar;