
import { Modal, Button } from "antd";
import React, {useRef} from "react";
import { useReactToPrint } from 'react-to-print';
import { NumericFormat } from 'react-number-format';
import moment from "moment";
import './ReportePrint.css'
export const ReportePrint = (props) => {
    const componentRef = useRef();
    const reporte = props.reporte;
    const tipo = props.tipo;
    

    const handleCancel = () =>{
      props.updateVisible(false)
    }

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
      });

      const calcularTotalItem = (items,impuesto) =>{
        let total = 0;
        let valorImpuesto = 0
        for(let item of items){
          total += item.precio_venta * item.cantidad
        }

        if(impuesto>0){
          valorImpuesto = total * (impuesto/100)
         }

         return total + valorImpuesto;
      }

      const calcularTotal = (reporte) =>{
       let myTotal = 0
       let valorImpuesto = 0
        for(let venta of reporte){
          if(venta.estado != "Anulada"){
           
          for(let producto of venta.FacturaItems){
            
            myTotal += producto.precio_venta * producto.cantidad 
          }
          if(venta.impuesto>0){
            valorImpuesto = myTotal * (venta.impuesto/100)
           }
        }
        }

        return myTotal + valorImpuesto
      }

        return (
            <Modal width={1024} visible={props.visible} onCancel={handleCancel} onOk={handleCancel}>
              {tipo == "VENTAS" && (
              <div ref={componentRef}>
                REPORTE DE {tipo}
                <div>DE <strong> {moment(props.fechaInicial).format("DD-MM-YYYY")} </strong> A <strong> {moment(props.fechaFinal).format("DD-MM-YYYY")} </strong></div> 

                <table className="ventas">
                  <thead>
                  <tr>
                    <th>No. FV</th>
                    <th>Subtotal</th>
                    <th>Impuesto %</th>
                    <th>Total</th>
                    <th>Estado</th>
                    <th>Fecha</th>
                    </tr>
                    </thead>
                   
                   <tbody>
                      {reporte.map((item=>{
                       return  <tr>
                         <td>{item.num_factura}</td>
                         <td> $ <NumericFormat value={calcularTotalItem(item.FacturaItems,0)} displayType="text" allowLeadingZeros thousandSeparator="," />  </td>
                         <td>  <NumericFormat value={item.impuesto} displayType="text" allowLeadingZeros thousandSeparator="," /> % </td>
                         <td> $ <NumericFormat value={calcularTotalItem(item.FacturaItems,item.impuesto)} displayType="text" allowLeadingZeros thousandSeparator="," />  </td>
                         <td>{item.estado}</td>
                         <td>{moment(item.createdAt).format('DD-MM-YYYY')}</td>
                        </tr>
                      }))}

                  
                      <tr>
                        <td></td>
                        <td></td>
                        <td> <strong>TOTAL</strong> </td>
                        <td> $ <NumericFormat value={calcularTotal(reporte)} displayType="text" allowLeadingZeros thousandSeparator="," /> </td>
                        <td> </td>
                        <td> </td>
                      </tr>
                   
                      </tbody>
                    
                </table>

                </div>
                )}

{tipo == "INSUMOS" && (
              <div ref={componentRef}>
                REPORTE DE {tipo}
               

                <table className="ventas">
                  <thead>
                  <tr>
                    <th>Insumo</th>
                    <th>Cantidad</th>
                    </tr>
                    </thead>
                   
                   <tbody>
                      {reporte.map((item=>{
                       return  <tr>
                         <td>{item.Insumo.nombre}</td>
                         <td> {item.cantidad} {item.Insumo.unidad}(es)  </td>
                        </tr>
                      }))}
                   
                      </tbody>
                    
                </table>

                </div>
                )}

{tipo == "PRODUCTOS" && (
              <div ref={componentRef}>
                REPORTE DE {tipo}
               

                <table className="ventas">
                  <thead>
                  <tr>
                    <th>Producto</th>
                    <th>Cantidad</th>
                    </tr>
                    </thead>
                   
                   <tbody>
                      {reporte.map((item=>{
                        if(item.Producto.tipo != "Compuesto"){
                       return  <tr>
                         <td>{item.Producto.nombre}</td>
                         <td> {item.cantidad} {item.Producto.unidad}(es)  </td>
                        </tr>
                        }
                      }))}
                   
                      </tbody>
                    
                </table>

                </div>
                )}
              <div className="botonera">
             <Button type="primary" onClick={handlePrint}>Imprimir Reporte</Button>
             </div>
            </Modal>
        )
  
}