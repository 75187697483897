import React, { useState, useEffect } from "react";
import Layout from "../../shared/Layout/Layout";
import { Table, Button, Modal, Form, Input } from "antd";
import apiService from "../../services/api";
import {EditOutlined,DeleteOutlined,ExclamationCircleOutlined} from '@ant-design/icons';
import { useAuth } from '../../context/auth.context';
const { confirm } = Modal;

const Categoria = () => {
  const [viewModal, setViewModal] = useState(false);
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const {user} = useAuth()
  const empresa_id = user.empresa_id
  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    apiService.getBy("categorias", empresa_id)
      .then((response) => {
        console.log(response.data);
        if(response.data.length>0){
        setData(response.data);
         }
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  const saveData = (values) => {
    console.log(values);
    let form = values
    form.empresa_id = empresa_id
      apiService.post("categorias", form)
        .then((response) => {
          console.log(response);
          setViewModal(false);
          //form.resetFields();
          getData()
        })
        .catch(function(error) {
          console.log(error);
        });
    
  };

  const deleteData = (record) =>{

    confirm({
      title: 'Desea Eliminar?',
      icon: <ExclamationCircleOutlined />,
      content: 'No se podra recuperar',
      onOk() {
        apiService.delete('categorias',record.id).then((response) => {
          console.log(response);
          //form.resetFields();
          getData()
        })
        .catch(function(error) {
          console.log(error);
        });
      },
      onCancel() {
        console.log('Cancel');
      },
    });

  
  }

  const callModal = () => {
    setViewModal(true);
  };

  const sendCancel = () => {
    setViewModal(false);
  };

  const columns = [
    {
      title: "Nombre",
      dataIndex: "nombre",
      key: "nombre",
      //render: (text) => <a>{text}</a>,
    },

    {
      title: "Opciones",

      render: (record) => <>
      {/* <Button><EditOutlined /></Button>  */}
      <Button onClick={()=>deleteData(record)} type="danger"><DeleteOutlined /></Button></> ,
    },
  ];

  return (
    <Layout>
      <Button type="primary" className="new_button" onClick={callModal}>
        Crear Categoría
      </Button>

      <Table columns={columns} dataSource={data} />

      <Modal
        title="Crear Categoría"
        visible={viewModal}
        onCancel={sendCancel}
        onOk={form.submit}
      >
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 20 }}
          autoComplete="off"
          layout="vertical"
          form={form}
          onFinish={saveData}
        >
          <Form.Item
            label="Nombre"
            name="nombre"
            rules={[{ required: true, message: "Ingrese el nombre" }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </Layout>
  );
};

export default Categoria;
