import React, { useState, useEffect } from "react";
import Layout from "../../shared/Layout/Layout";
import { Table, Button, Modal, Form, Input, Select, Row, Col } from "antd";
import { NumericFormat } from 'react-number-format';
import { useNavigate } from "react-router-dom"
import apiService from "../../services/api";
import { useAuth } from '../../context/auth.context';
import {EditOutlined,UnorderedListOutlined,DeleteOutlined,ExclamationCircleOutlined} from '@ant-design/icons';
const { confirm } = Modal;

const Producto = () => {
  const [viewModal, setViewModal] = useState(false);
  const [viewEditModal, setViewEditModal] = useState(false);
  const [form] = Form.useForm();
  const [formEdit] = Form.useForm();
  const [data, setData] = useState([]);
  //const [bodegas, setBodegas] = useState([]);
  const [categorias, setCategorias] = useState([]);
  const [viewUnico, setViewUnico] = useState(true);
  const navigate = useNavigate()
  const [itemSelected, setItemSelected] = useState();
  const {user,bodegas} = useAuth()
  const empresa_id = user.empresa_id


  useEffect(() => {
    getData();
    getBodegas()
    getCategorias()
  }, []);

  const getData = () => {
    apiService.getBy("productos", empresa_id)
      .then((response) => {
        let myData = response.data
        if(myData.length>0){
          myData = myData.filter(item=>item.estado == "Activo")
        setData(myData)
      
      }
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  const getBodegas = () => {
    //setBodegas(bodegas)
    // apiService.getBy("bodegas", empresa_id)
    //   .then((response) => {
    //     console.log(response.data);
    //     if(response.data.length>0){
    //     setBodegas(response.data)
      
    //   }
    //   })
    //   .catch(function(error) {
    //     console.log(error);
    //   });
  };

  const getCategorias = () => {
    apiService.getBy("categorias", empresa_id)
      .then((response) => {
        console.log(response.data);
        if(response.data.length>0){

        setCategorias(response.data)
      
      }
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  const saveData = (values) => {
    console.log(values);
    let form = values;
    form.empresa_id = empresa_id
    apiService.post("productos", form)
      .then((response) => {
        console.log(response);
        setViewModal(false);
        getData()
        //form.resetFields();
      })
      .catch(function(error) {
        console.log(error);
      });

    // const pushData = [...datos, values];
    // setDatos(pushData);
  };

  const inactiveData = (record) =>{

    confirm({
      title: 'Desea Eliminar?',
      icon: <ExclamationCircleOutlined />,
      content: 'No se podra recuperar',
      onOk() {
        apiService.inactive('productos/inactive',record.id).then((response) => {
          console.log(response);
          //form.resetFields();
          getData()
        })
        .catch(function(error) {
          console.log(error);
        });
      },
      onCancel() {
        console.log('Cancel');
      },
    });

  
  }

  const callModal = () => {
    form.resetFields()
    setViewModal(true);
  };

  const sendCancel = () => {
    setViewModal(false);
    setViewEditModal(false)
  };

  const handleTipo = (value) =>{
    console.log(value)
    value == "Compuesto" ? setViewUnico(false) : setViewUnico(true)
  }

  const toInventario = (record) =>{
    navigate("/producto/insumo", { state: { id: record.id, nombre:record.nombre } });
  }

  const toEdit = (record) =>{
    setViewEditModal(true)
    formEdit.setFieldsValue(record)
    handleTipo(record.tipo)
    setItemSelected(record)
  }

  const editData = (values) =>{
    apiService.update('productos',itemSelected.id,values).then((response) => {
      console.log(response);
      setViewEditModal(false);
      getData()
      //form.resetFields();
    })
    .catch(function(error) {
      console.log(error);
    });
  }

  const columns = [
    {
      title: "Nombre",
      dataIndex: "nombre",
      key: "nombre",
      //render: (text) => <a>{text}</a>,
    },

    {
      title: "Categoria",
      dataIndex: "categoria",
      key: "categoria",
    },

    {
      title: "Precio Venta",
      //dataIndex: "valor_venta",
      //key: "valor_venta",
      render: (record) =>  <NumericFormat value={record.valor_venta} displayType="text" allowLeadingZeros thousandSeparator="," />,
    },

    {
      title: "Tipo de Producto",
      dataIndex: "tipo",
      key: "tipo",
    },

    {
      title: "Opciones",

      render: (record) => {
        if(record.tipo == "Compuesto"){ 
          return <div><Button onClick={()=>toEdit(record)}><EditOutlined /></Button>  <Button onClick={()=>toInventario(record)}><UnorderedListOutlined /></Button> <Button onClick={()=>inactiveData(record)} type="danger"><DeleteOutlined /></Button> </div>
        }
        else{
          return <div><Button onClick={()=>toEdit(record)}><EditOutlined /></Button> <Button onClick={()=>inactiveData(record)} type="danger"><DeleteOutlined /></Button></div>
        }
      } ,
    },
  ];

  return (
    <Layout>
      <Button type="primary" className="new_button" onClick={callModal}>
        Crear Producto
      </Button>

      <Table columns={columns} dataSource={data} />

      <Modal
        title="Crear Producto"
        visible={viewModal}
        onCancel={sendCancel}
        onOk={form.submit}
      >
        <Form
          name="basic"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 20 }}
          autoComplete="off"
          layout="vertical"
          form={form}
          onFinish={saveData}
        >

            <Row>
                <Col span={12}>
          <Form.Item
            label="Nombre"
            name="nombre"
            rules={[{ required: true, message: "Ingrese el nombre" }]}
          >
            <Input />
          </Form.Item>
                 </Col>

                 <Col span={12}>
          <Form.Item
            label="Tipo de Producto"
            name="tipo"
            rules={[
              { required: true, message: "Ingrese el tipo del Producto" },
            ]}
          >
            <Select onChange={handleTipo}>
              <Select.Option value="Único">Único</Select.Option>
              <Select.Option value="Compuesto">Compuesto</Select.Option>
            </Select>
          </Form.Item>
          </Col>

          <Col span={12}>
          <Form.Item
            label="Categoria"
            name="categoria"
            rules={[
              { required: true, message: "Ingrese La categoria" },
            ]}
          >
            <Select>
            {categorias.map((item =>{
               return  <Select.Option value={item.nombre}>{item.nombre}</Select.Option>
              }))}
              
            </Select>
          </Form.Item>
          </Col>

          <Col span={12}>
          <Form.Item
            label="Marca"
            name="marca"
            rules={[{ required: false, message: "Ingrese la marca" }]}
          >
            <Input />
          </Form.Item>
          </Col>

          <Col span={12}>
          <Form.Item
            label="Código de Barras"
            name="barras"
            rules={[
              { required: false, message: "Ingrese el código de Barras" },
            ]}
          >
            <Input />
          </Form.Item>
          </Col>

            {viewUnico && (
          <Col span={12}>
          <Form.Item
            label="Valor de compra"
            name="valor_compra"
            rules={[{ required: false, message: "Ingrese el valor de Compra" }]}
          >
            <Input prefix="$" />
          </Form.Item>
          </Col>
          )}

          <Col span={12}>
          <Form.Item
            label="Valor de venta"
            name="valor_venta"
            rules={[{ required: true, message: "Ingrese el valor de Venta" }]}
          >
            <Input prefix="$" />
          </Form.Item>

          </Col>

         
        
                 <Col span={12}>
          <Form.Item
            label="Bodega"
            name="bodega_id"
            rules={[
              { required: true, message: "Ingrese La bodega" },
            ]}
          >
            <Select>
              {bodegas.map((item =>{
               return  <Select.Option value={item.id}>{item.Bodega.nombre}</Select.Option>
              }))}
            </Select>
          </Form.Item>
          </Col>
        

{viewUnico && (
          <Col span={12}>
          <Form.Item
            label="Cantidad Inicial"
            name="cantidad_inicial"
            rules={[{ required: false, message: "Ingrese cantidad Inicial" }]}
          >
            <Input />
          </Form.Item>
                 </Col>
     )}

          <Col span={12}>
          <Form.Item
            label="Unidad de medida"
            name="unidad"
            rules={[
              { required: true, message: "Ingrese La unidad de medida" },
            ]}
          >
            <Select>
              <Select.Option value="unidad">unidad</Select.Option>
              <Select.Option value="kilo">kilo</Select.Option>
              <Select.Option value="libra">libra</Select.Option>
              <Select.Option value="gramo">gramo</Select.Option>
              <Select.Option value="litro">litro</Select.Option>
              <Select.Option value="metro">metro</Select.Option>
              <Select.Option value="centímetro">centímetro</Select.Option>
            </Select>
          </Form.Item>
          </Col>

          </Row>

        </Form>
      </Modal>

      <Modal
        title="Editar Producto"
        visible={viewEditModal}
        onCancel={sendCancel}
        onOk={formEdit.submit}
      >
        <Form
          name="basic"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 20 }}
          autoComplete="off"
          layout="vertical"
          form={formEdit}
          onFinish={editData}
        >

            <Row>
                <Col span={12}>
          <Form.Item
            label="Nombre"
            name="nombre"
            rules={[{ required: true, message: "Ingrese el nombre" }]}
          >
            <Input />
          </Form.Item>
                 </Col>

                 <Col span={12}>
          <Form.Item
            label="Tipo de Producto"
            name="tipo"
            rules={[
              { required: true, message: "Ingrese el tipo del Producto" },
            ]}
          >
            <Select onChange={handleTipo}>
              <Select.Option value="Único">Único</Select.Option>
              <Select.Option value="Compuesto">Compuesto</Select.Option>
            </Select>
          </Form.Item>
          </Col>

          <Col span={12}>
          <Form.Item
            label="Categoria"
            name="categoria"
            rules={[
              { required: true, message: "Ingrese La categoria" },
            ]}
          >
            <Select>
            {categorias.map((item =>{
               return  <Select.Option value={item.nombre}>{item.nombre}</Select.Option>
              }))}
              
            </Select>
          </Form.Item>
          </Col>

          <Col span={12}>
          <Form.Item
            label="Marca"
            name="marca"
            rules={[{ required: false, message: "Ingrese la marca" }]}
          >
            <Input />
          </Form.Item>
          </Col>

          <Col span={12}>
          <Form.Item
            label="Código de Barras"
            name="barras"
            rules={[
              { required: false, message: "Ingrese el código de Barras" },
            ]}
          >
            <Input />
          </Form.Item>
          </Col>

            {viewUnico && (
          <Col span={12}>
          <Form.Item
            label="Valor de compra"
            name="valor_compra"
            rules={[{ required: false, message: "Ingrese el valor de Compra" }]}
          >
            <Input prefix="$" />
          </Form.Item>
          </Col>
          )}

          <Col span={12}>
          <Form.Item
            label="Valor de venta"
            name="valor_venta"
            rules={[{ required: true, message: "Ingrese el valor de Venta" }]}
          >
            <Input prefix="$" />
          </Form.Item>

          </Col>

         
        
        

{viewUnico && (
          <Col span={12}>
          <Form.Item
            label="Cantidad Inicial"
            name="cantidad_inicial"
            rules={[{ required: false, message: "Ingrese cantidad Inicial" }]}
          >
            <Input />
          </Form.Item>
                 </Col>
     )}

          <Col span={12}>
          <Form.Item
            label="Unidad de medida"
            name="unidad"
            rules={[
              { required: true, message: "Ingrese La unidad de medida" },
            ]}
          >
            <Select>
              <Select.Option value="unidad">unidad</Select.Option>
              <Select.Option value="kilo">kilo</Select.Option>
              <Select.Option value="libra">libra</Select.Option>
              <Select.Option value="gramo">gramo</Select.Option>
              <Select.Option value="litro">litro</Select.Option>
              <Select.Option value="metro">metro</Select.Option>
              <Select.Option value="centímetro">centímetro</Select.Option>
            </Select>
          </Form.Item>
          </Col>

          </Row>

        </Form>
      </Modal>
    </Layout>
  );
};

export default Producto;
