import React from 'react';
import {Row, Col, Button} from 'antd'
import {DeleteOutlined} from '@ant-design/icons';
import { NumericFormat } from 'react-number-format';
import "./ItemPos.css"
const ItemPos = (props) => {

    let myItem = props.children
    let myIndex = props.indice

    const addCantidad = (indice) =>{
        props.updateCantidad('agregar',indice)
    }

    const deleteCantidad = (indice) =>{
        props.updateCantidad('quitar',indice)
    }

    const deleteItem = (indice) =>{
        props.deleteItem(indice)
    }
    
    return (
        <Row className='item_pos'>
            <Col span={8}>
                <div>{myItem.nombre} </div>
                <div> <NumericFormat value={myItem.precio_venta} displayType="text" allowLeadingZeros thousandSeparator="," /> </div>
            </Col>

            <Col span={8} className='buttons'>
            <Button onClick={()=>deleteCantidad(myIndex)}>-</Button>
            <span className='cantidad'>{myItem.cantidad}</span>
            <Button onClick={()=>addCantidad(myIndex)}>+</Button>
            </Col>


            <Col span={8} className="total">
           
            <span><NumericFormat value={myItem.total} displayType="text" allowLeadingZeros thousandSeparator="," /> </span>
            <Button onClick={()=>deleteItem(myIndex)}><DeleteOutlined /></Button>
            </Col>

        </Row>
    );
};

export default ItemPos;