import React, {useState} from 'react';
import Layout from '../../shared/Layout/Layout';
import { useAuth } from '../../context/auth.context';
import { Select,Form,Button, Input } from 'antd';
import apiService from '../../services/api';
import { ReportePrint } from '../../shared/ReportePrint/ReportePrint';
import moment from 'moment';
const Reporte = () => {
    const {user,bodegas} = useAuth()
    const [form] = Form.useForm();
    const [reporte,setReporte] = useState()
    const [tipo,setTipo] = useState()
    const [fechaInicial,setFechaInicial] = useState(moment().format('YYYY-MM-DD'))
    const [fechaFinal,setFechaFinal] = useState(moment().format('YYYY-MM-DD'))
    const [viewModalReporte,setViewModalReporte] = useState()
    const [viewFechas, setViewFechas] = useState(false)

    const generar = (values) =>{
        console.log(values)
        setTipo(values.tipo)
        setFechaInicial(values.fechaInicial)
        setFechaFinal(values.fechaFinal)
        apiService.post('bodegas/reportes',values).then(res=>{
            if(res.data.length>0){
                setViewModalReporte(true)
            setReporte(res.data)
            console.log(res.data)
        }
        })
    } 

    const validarTipo = (valor) =>{
        if(valor == 'VENTAS'){
            setViewFechas(true)
        }
        else{
            setViewFechas(false)
        }
    }

    return (
        <Layout>
            <Form layout="vertical" form={form} onFinish={generar}>
                <Form.Item label="Bodega" name="bodega_id"  rules={[{ required: true, message: 'Please ingrese Bodega!' }]}>
                    {bodegas && (
                    <Select placeholder="Seleccione Bodega">
                    {bodegas.map((item=>{
                        return <Select.Option value={item.id}>{item.Bodega.nombre}</Select.Option>
                    }))}
                    </Select>
                    )}
                </Form.Item>

                <Form.Item label="tipo" name="tipo"  rules={[{ required: true, message: 'Please ingrese Tipo de Reporte!' }]}>
                  
                    <Select placeholder="Seleccione Reporte" onChange={validarTipo}>
                  
                        <Select.Option value="INSUMOS">Inventario de Insumos</Select.Option>
                        <Select.Option value="PRODUCTOS">Inventario de Productos</Select.Option>
                        <Select.Option value="VENTAS">Reporte de Ventas</Select.Option>
         
                    </Select>
             
                </Form.Item>

{viewFechas && (
    <>
                <Form.Item
        label="Fecha Inicial"
        name="fechaInicial"
        rules={[{ required: false, message: 'Please ingrese Fecha!' }]}
      >
        <Input type="date" />
      </Form.Item>

      <Form.Item
        label="Fecha Final"
        name="fechaFinal"
        rules={[{ required: false, message: 'Please ingrese Fecha!' }]}
      >
        <Input type="date" />
      </Form.Item>
      </>
)}
      

                <Form.Item>
                    <Button htmlType="submit">Generar</Button>
                    
                </Form.Item>
            </Form>

                        {viewModalReporte && (
                    <ReportePrint visible={viewModalReporte} updateVisible= {setViewModalReporte} 
                    reporte={reporte} tipo={tipo} fechaInicial={fechaInicial} fechaFinal={fechaFinal}>
                    </ReportePrint>
                    )}

        </Layout>
    );
};

export default Reporte;