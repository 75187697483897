import React, {useState} from 'react';
import {
    Modal,
    Form,
    Col,
    Row,
    Input,
    DatePicker,
    Select,
    message,
  } from "antd";
  import { Content } from "antd/lib/layout/layout";
import { useEffect } from 'react';
import apiService from "../../services/api"
import { useAuth } from '../../context/auth.context';

const AddProductoModal = (props) => {

    const bodegaSelected = props.bodega_id
    const [form] = Form.useForm();
    const {user} = useAuth()
  const empresa_id = user.empresa_id
  const [data, setData] = useState([]);
  const [viewCantidad, setViewCantidad] = useState([]);

    useEffect(()=>{
        form.resetFields()
        getData()
    },[])

    const getData = () => {
        apiService.getBy("productos", empresa_id)
          .then((response) => {
            let myData = response.data
            if(myData.length>0){
              myData = myData.filter(item=>item.estado == "Activo")
            setData(myData)
          
          }
          })
          .catch(function(error) {
            console.log(error);
          });
      };


    const handleCancel = () => {
        props.updateVisible(false);
      };

      const onFinish = (values) =>{
        console.log(values)
        let form = values
        form.bodega_id = bodegaSelected
        apiService.post("inventario",form).then((response) => {
          console.log(response.data);
          props.updateLoading(false);
          props.updateVisible(false);
          props.updateListInventario();
          message.success("Guardado Correctamente");
        })
        .catch((e) => {
          props.updateLoading(false);
          message.error("No se ha podido guardar");
          console.log(e);
        });
      }

      const validarTipo=(e)=>{
        console.log(e)
        let filtro = data.filter(item=>item.id == e)
        if(filtro.length>0){
          let myProductoType = filtro[0].tipo
          if(myProductoType == "Compuesto"){
            setViewCantidad(false)
            form.setFieldsValue({cantidad:null})
          }else{
            setViewCantidad(true)
          }
        }
      }

    return (
        <Modal title="Agregar Producto" visible={props.visible} onCancel={handleCancel} onOk={form.submit} >
        <Content>
   
   <Form
     name="basic"
     layout="vertical"
     labelCol={{ span: 24 }}
     wrapperCol={{ span: 20 }}
     autoComplete="off"
     onFinish={onFinish}
     form={form}
   >
     <Row>

     <Col span={24}>
           <Form.Item
             label="Producto: "
             name="producto_id"
             rules={[
               {
                 required: false,
                 message: "Por favor ingrese Producto",
               },
             ]}
           >
            <Select onChange={validarTipo}>
                {data.map((item=>{
                    return <Select.Option value={item.id}>{item.nombre}</Select.Option>
                }))}
            </Select>
           </Form.Item>
         </Col>

{viewCantidad && (
       <Col span={24}>
           <Form.Item
             label="Cantidad: "
             name="cantidad"
             rules={[
               {
                 required: true,
                 message: "Por favor ingrese Cantidad",
               },
             ]}
           >
             <Input type="number" />
           </Form.Item>
         </Col>
)}
        


     </Row>
   </Form>
 </Content>
     </Modal>
    );
};

export default AddProductoModal;