import React, { useState, useEffect,useRef } from "react";
import Layout from "../../shared/Layout/Layout";
import { Row, Col, Input, Table, Button, Switch, Spin } from "antd";
import { NumericFormat } from 'react-number-format';
import "./Pos.css";
import ItemPos from "../../shared/ItemPos/ItemPos";
import {PlusCircleOutlined} from '@ant-design/icons';
import { useAuth } from '../../context/auth.context';
import apiService from "../../services/api";
import { useLocation } from "react-router-dom";
import {FacturaPrint} from "../../shared/FacturaPrint/FacturaPrint";
// import { useReactToPrint } from 'react-to-print';

const Pos = () => {
  const location = useLocation();
    const {id,nombre,impuesto} = location.state
  const [items, setItems] = useState([]);
  const [data, setData] = useState([]);
  const [dataFilter, setDataFilter] = useState([]);
  const [total, setTotal] = useState(0);
  const [factura, setFactura] = useState();
  const [viewModalFactura, setViewModalFactura] = useState(true);
  const [viewImpuesto, setViewImpuesto] = useState(true);
  const [cambio,setCambio] = useState(0);
  const inputEfectivo = useRef(0);
  const [loading, setLoading] = useState(false);
  // const componentRef = useRef();
  // const handlePrint = useReactToPrint({
  //   content: () => componentRef.current,
  // });

  const handlePrint = (factura) =>{
    console.log("MY FACTURA", factura)
  }

  const {user} = useAuth()
  const usuario_id = user.id
  const bodega_id = id;

  useEffect(() => {
    getInventario();
  }, []);

  const getInventario = () => {
    apiService
      .getBy("inventario", bodega_id)
      .then((response) => {
        console.log(response.data);
        if (response.data.length > 0) {
          setData(formatInventario(response.data));
          setDataFilter(formatInventario(response.data));
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  const formatInventario = (data) => {
    let myArrayData = [];
    for (let item of data) {
      if(item.Producto.estado == "Activo"){
      let myObjectsData = {};
      myObjectsData["id"] = item.producto_id;
      myObjectsData["cantidad_inventario"] = item.cantidad;
      myObjectsData["nombre"] = item.Producto.nombre;
      myObjectsData["categoria"] = item.Producto.categoria;
      myObjectsData["tipo"] = item.Producto.tipo;
      myObjectsData["precio_venta"] = item.Producto.valor_venta;
      if(item.cantidad > 0 && item.Producto.tipo == "Único" ){
      myArrayData.push(myObjectsData);
    }
    else if(item.Producto.tipo == "Compuesto"){
      myArrayData.push(myObjectsData);
    }
    }
  }
    return myArrayData;
  };

  const updateCantidad = (tipo, indice) => {
    if (tipo == "agregar") {
      const newState = items.map((obj) => {
        if (obj.id === indice) {
            if(obj.cantidad_inventario > obj.cantidad && obj.tipo == "Único"){
          setTotal(total + obj.precio_venta);
          return {
            ...obj,
            cantidad: obj.cantidad + 1,
            total: obj.total + obj.precio_venta,
        }
    } else if(obj.tipo == "Compuesto"){
        setTotal(total + obj.precio_venta);
          return {
            ...obj,
            cantidad: obj.cantidad + 1,
            total: obj.total + obj.precio_venta,
        }
    }
    }
        return obj;
      });
      
      setItems(newState);
    } else {
      if (tipo == "quitar") {
        const newState = items.map((obj) => {
          if (obj.id === indice) {
            if (obj.cantidad > 1) {
              setTotal(total - obj.precio_venta);
              return {
                ...obj,
                cantidad: obj.cantidad - 1,
                total: obj.total - obj.precio_venta,
              };
            }
          }

          return obj;
        });
        setItems(newState);
      }
    }
  };

  const addItem = (item) => {
    
    console.log(item)
    item.cantidad = 1;
    item.total = item.cantidad * item.precio_venta;
    item.tipo = item.tipo

    let find = items.filter((producto) => producto.id == item.id);

    if (find.length == 0) {
      setTotal(total + item.cantidad * item.precio_venta);
      const pushItem = [...items, item];
      setItems(pushItem);
    
    }
  };

  const deleteItem = (indice) => {
    let find = items.filter((producto) => producto.id == indice);
    if (find.length != 0) {
      setTotal(total - find[0].cantidad * find[0].precio_venta);
    }

    const newState = items.filter((item) => {
      return item.id !== indice;
    });
   
    setItems(newState);
    
  };

  const facturar = () =>{
    setLoading(true)
    setFactura(null)
    let myImpuesto = 0
    if(viewImpuesto){
      myImpuesto=impuesto
    } 

    let factura = {
      bodega_id:bodega_id,
      usuario_id:usuario_id,
      items:items,
      impuesto:myImpuesto
     
    }

    console.log(factura)

    apiService.post("facturas", factura)
        .then((response) => {
          console.log(response);
         
          //form.resetFields();
          setItems([])
          setTotal(0)
          setCambio(0)
          
          //inputEfectivo.current.value = 0;
          getInventario()
          setFactura(response.data[0])
          setLoading(false)
          setViewModalFactura(true)
          handlePrint(response.data[0])
        })
        .catch(function(error) {
          setLoading(false)
          console.log(error);
        });

    console.log(items)
  }

  const columns = [
    {
      title: "Nombre",
      // dataIndex: "nombre",
      // key: "nombre",
      render:(record)=>{ return <div>{record.nombre} <span className="categoria">{record.categoria}</span></div>}
    },

    {
      title: "Cantidad",
      dataIndex: "cantidad_inventario",
      key: "cantidad_inventario",
    },
    {
      title: "Precio Venta",
      //dataIndex: "precio_venta",
      //key: "precio_venta",
       render: (record) => <NumericFormat value={record.precio_venta} displayType="text" allowLeadingZeros thousandSeparator="," />,
    },

    {
      title: "Opciones",

      render: (record) => (
        <Button onClick={() => addItem(record)}><PlusCircleOutlined /></Button>
      ),
    },
  ];

  const filtrarTabla = (e) =>{
    console.log(e.target.value)
    let textoBusqueda = e.target.value
    let values = data.filter(item=>item.nombre.toLowerCase().includes(textoBusqueda.toLowerCase()))
    console.log(values)
    setDataFilter(values)
    
  }

  const changeImpuesto = (value) =>{
    // setCambio(0)
   setViewImpuesto(value)
  //  if(value){
  //   setCambio(inputEfectivo.current.input.value - (total + total*impuesto/100)) 
  // }
  // else{
  //   setCambio(inputEfectivo.current.input.value - total)
  // }
  }

  

  const calcularRegreso = (valor) =>{
    let efectivo = valor.target.value
    setCambio(efectivo)
    // if(viewImpuesto){
    //   setCambio(efectivo - (total + total*impuesto/100)) 
    // }
    // else{
    //   setCambio(efectivo - total)
    // }
  }
  if (loading)
  return (
    <div className="spin_container">
      <Spin className="spin" size="large" />
    </div>
  );
  return (
    <Layout>
      <div>POS - {nombre}</div>

      <Row>
        <Col span={12}>
          <div className="left_panel">
            <div className="search">
              <Input placeholder="Buscar Producto" onChange={filtrarTabla}></Input>
            </div>
            <Table dataSource={dataFilter} columns={columns}></Table>
          </div>
        </Col>
        <Col span={12}>
          <div className="right_panel">
            <div className="search">
              {/* <Input placeholder="Buscar Cliente"></Input> */}
            </div>

            <div className="titulo_factura">Factura de Venta</div>

            {items.map((item, index) => {
              return (
                <ItemPos
                  key={index}
                  indice={item.id}
                  updateCantidad={updateCantidad}
                  deleteItem={deleteItem}
                >
                  {item}
                </ItemPos>
              );
            })}




{ factura && (
  <FacturaPrint visible={viewModalFactura}  value={factura} updateVisible= {setViewModalFactura} />
  )}
  <Row>
{items.length > 0 && impuesto > 0 && (
  <Col span={8} className="opt-factura">
   <div><strong>Impuesto: </strong></div> 
    <Switch checkedChildren={impuesto + "%"} unCheckedChildren="0" defaultChecked onChange={changeImpuesto} />
    </Col>
  )}



    <Col span={8} className="opt-factura"> <div> <strong>Efectivo:</strong>  </div>
    <input className="ant-input" ref={inputEfectivo} onChange={calcularRegreso}></input>
    </Col>
    <Col span={8} className="opt-factura">  <div> <strong>Cambio:</strong> </div> 
    {viewImpuesto && (
     <NumericFormat value={cambio - (total + total*impuesto/100)} displayType="text" allowLeadingZeros thousandSeparator="," />
    )}

{!viewImpuesto && (
     <NumericFormat value={cambio - total} displayType="text" allowLeadingZeros thousandSeparator="," />
    )}
     </Col>
 

  </Row>
 
 

{items.length > 0 && viewImpuesto && (
            <div className="boton_vender" onClick={facturar}>Vender $ <NumericFormat value={total + total*impuesto/100} displayType="text" allowLeadingZeros thousandSeparator="," /></div>
            )}

{items.length > 0 && !viewImpuesto && (
            <div className="boton_vender" onClick={facturar}>Vender $ <NumericFormat value={total } displayType="text" allowLeadingZeros thousandSeparator="," /></div>
            )}
          
          </div>
        </Col>
      </Row>
    </Layout>
  );
};

export default Pos;
