import React from 'react';
import {
    Modal,
    Form,
    Col,
    Row,
    Input,
    DatePicker,
    Select,
    message,
  } from "antd";
  import { Content } from "antd/lib/layout/layout";
import { useEffect } from 'react';
import apiService from "../../services/api"
const AjusteModal = (props) => {

    const itemSelected = props.children
    const [form] = Form.useForm();

    useEffect(()=>{
        form.resetFields()
    },[])

    const handleCancel = () => {
        props.updateVisible(false);
      };

    const onFinish = (values) =>{
        let form = values
        form.bodega_id = props.bodega_id
        if(props.tipo == "Producto"){
        form.producto_id = itemSelected.id
        console.log(form)

        apiService.post("inventario/ajuste",form).then((response) => {
            console.log(response.data);
            props.updateLoading(false);
            props.updateVisible(false);
            props.updateListInventario();
            message.success("Guardado Correctamente");
          })
          .catch((e) => {
            props.updateLoading(false);
            message.error("No se ha podido guardar");
            console.log(e);
          });
        }

        if(props.tipo == "Insumo"){
            form.insumo_id = itemSelected.id
            console.log(form)
    
            apiService.post("inventario/insumos/ajuste",form).then((response) => {
                console.log(response.data);
                props.updateLoading(false);
                props.updateVisible(false);
                props.updateListInventario();
                message.success("Guardado Correctamente");
              })
              .catch((e) => {
                props.updateLoading(false);
                message.error("No se ha podido guardar");
                console.log(e);
              });
            }
    }

    return (
        <Modal title={itemSelected.nombre} visible={props.visible} onCancel={handleCancel} onOk={form.submit} >
           <Content>
      
      <Form
        name="basic"
        layout="vertical"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 20 }}
        autoComplete="off"
        onFinish={onFinish}
        form={form}
      >
        <Row>

        

          <Col span={24}>
            <Form.Item
              label="Agregar/Quitar: "
              name="accion"
              rules={[
                {
                  required: true,
                  message: "Por favor ingrese el movimiento",
                },
              ]}
            >
              <Select>
                  <Select.Option value="Agregar">Agregar</Select.Option>
                  <Select.Option value="Quitar">Quitar</Select.Option>
              </Select>
            </Form.Item>
          </Col>


          <Col span={24}>
              <Form.Item
                label="Cantidad: "
                name="cantidad"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese Cantidad",
                  },
                ]}
              >
                <Input type="number" />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                label="Comentario: "
                name="comentario"
                rules={[
                  {
                    required: false,
                    message: "Por favor ingrese Comentario",
                  },
                ]}
              >
                <Input/>
              </Form.Item>
            </Col>


        </Row>
      </Form>
    </Content>
        </Modal>
    );
};

export default AjusteModal;