import React, {useState, useEffect} from 'react';
import Layout from '../../shared/Layout/Layout';
import { useLocation } from "react-router-dom";
import { Table, Button, Modal, Form, Input, Select, Divider } from "antd";
import apiService from "../../services/api";
import { useAuth } from '../../context/auth.context';
import {EditOutlined,DeleteOutlined,ExclamationCircleOutlined} from '@ant-design/icons';
const { confirm } = Modal;

const ProductoInsumo = () => {
    const location = useLocation();
    const {id,nombre} = location.state
    const [data,setData] = useState([])
    const [insumos,setInsumos] = useState([])
    const [viewModal, setViewModal] = useState(false);
    const [viewEditModal, setViewEditModal] = useState(false);
    const [form] = Form.useForm();
    const [formEdit] = Form.useForm();
    const producto_id = id
    const {user} = useAuth()
    const [itemSelected, setItemSelected] = useState();
    const empresa_id = user.empresa_id

  useEffect(() => {
    getData();
    getInsumos();
  }, []);

  const getData = () => {
    apiService.getBy("productoinsumos", producto_id)
      .then((response) => {
        console.log(response.data);
        if(response.data.length>0){
        setData(response.data);
         }
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  const getInsumos = () => {
    apiService.getBy("insumos", empresa_id)
      .then((response) => {
        let myData = response.data
        if(myData.length>0){
          myData = myData.filter(item=>item.estado == "Activo")
        setInsumos(myData)
         }
      })
      .catch(function(error) {
        console.log(error);
      });
  };


  const saveData = (values) => {
    console.log(values);
    let form = values
    form.producto_id = producto_id
      apiService.post("productoinsumos", form)
        .then((response) => {
          console.log(response);
          setViewModal(false);
          //form.resetFields();
          getData()
        })
        .catch(function(error) {
          console.log(error);
        });
    
  };

  const deleteData = (record) =>{

    confirm({
      title: 'Desea Eliminar?',
      icon: <ExclamationCircleOutlined />,
      content: 'No se podra recuperar',
      onOk() {
        apiService.delete('productoinsumos',record.id).then((response) => {
          console.log(response);
          //form.resetFields();
          getData()
        })
        .catch(function(error) {
          console.log(error);
        });
      },
      onCancel() {
        console.log('Cancel');
      },
    });

  
  }

  const columns = [
    {
      title: "Nombre",
      render: (record) => record.Insumo.nombre,
    },

    {
        title: "Cantidad",
        render: (record) =>  <div>{record.cantidad} ({ record.Insumo.unidad})</div> ,
      },

    {
      title: "Opciones",

      render: (record) => <><Button onClick={()=>toEdit(record)}><EditOutlined /></Button> <Button onClick={()=>deleteData(record)} type="danger"><DeleteOutlined /></Button></> ,
    },
  ];

  const callModal = () => {
    setViewModal(true);
  };

  const sendCancel = () => {
    setViewModal(false);
    setViewEditModal(false)
  };

  const toEdit = (record) =>{
    setViewEditModal(true)
    formEdit.setFieldsValue(record)
    setItemSelected(record)
  }


  const editData = (values) =>{
    apiService.update('productoinsumos',itemSelected.id,values).then((response) => {
      console.log(response);
      setViewEditModal(false);
      getData()
      //form.resetFields();
    })
    .catch(function(error) {
      console.log(error);
    });
  }

    return (
        <Layout>

<Divider orientation="left" plain>
Insumos de <strong>{nombre}</strong> 
    </Divider>

            <Button type="primary" className="new_button" onClick={callModal}>
        Asociar Insumo
      </Button>

      <Table columns={columns} dataSource={data} />


      <Modal
        title="Asociar Insumo"
        visible={viewModal}
        onCancel={sendCancel}
        onOk={form.submit}
      >
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 20 }}
          autoComplete="off"
          layout="vertical"
          form={form}
          onFinish={saveData}
        >
          <Form.Item
            label="Insumo"
            name="insumo_id"
            rules={[{ required: true, message: "Ingrese el Insumo" }]}
          >
            <Select>
            {insumos.map((item =>{
               return  <Select.Option value={item.id}>{item.nombre} ({item.unidad})</Select.Option>
              }))}
              </Select>

          </Form.Item>

          <Form.Item
            label="Cantidad"
            name="cantidad"
            rules={[{ required: true, message: "Ingrese cantidad del Insumo" }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>


      <Modal
        title="Editar Insumo Asociado"
        visible={viewEditModal}
        onCancel={sendCancel}
        onOk={formEdit.submit}
      >
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 20 }}
          autoComplete="off"
          layout="vertical"
          form={formEdit}
          onFinish={editData}
        >
          
          <Form.Item
            label="Cantidad"
            name="cantidad"
            rules={[{ required: true, message: "Ingrese cantidad del Insumo" }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
      
        
        </Layout>
    );
};

export default ProductoInsumo;