import React, {createContext,useContext, useState} from 'react';

export const authContext = createContext()

export const useAuth = () =>{
    const context = useContext(authContext)
    if(!context) throw new Error("No User")
    return context
}

export function AuthProvider ({children}) {
    let userTest;
     //let userTest = {"empresa_id": 1, "id": 1,"nombre": "Julian Benavides","password": "Julian123", "username": "julian" }
    const [user,setUser] = useState(userTest)
    const [role,setRole] = useState()
    const [bodegas,setBodegas] = useState()

    const saveUser = (user) => {
        setUser(user)
    }

    const saveRole = (role) => {
        setRole(role)
    }

    const saveBodegas = (bodegas) => {
        setBodegas(bodegas)
    }
   

    return (
        <authContext.Provider value={{user,saveUser,role,saveRole,bodegas,saveBodegas}}>
            {children}
        </authContext.Provider>
    );
};

